<template>
	<div class="page-live-mobile">
		<div v-if="track" class="live-track is-mobile">
			<div class="live-track__content container-full is-mobile">
				<div class="live-track__aside is-mobile">
					<div class="live-track__info is-mobile">
						<div class="live-track__info__tabs is-mobile">
							<a :class="{ 'is-active': infoTab === 'info' }" @click.prevent="infoTab = 'info'">
								<inline-svg :src="require('../assets/information.svg')" width="26"></inline-svg>
								<span>Info<span class="is-hidden-touch">rmation</span></span>
							</a>
							<a :class="{ 'is-active': infoTab === 'chat' }" @click.prevent="infoTab = 'chat'">
								<inline-svg :src="require('../assets/topic.svg')" width="26"></inline-svg>
								<span>Chat</span>
							</a>
							<a :class="{ 'is-active': infoTab === 'vote' }" @click.prevent="infoTab = 'vote'">
								<inline-svg :src="require('../assets/poll.svg')" width="26"></inline-svg>
								<span>Vote</span>
							</a>
							<a :class="{ 'is-active': infoTab === 'evaluation' }" @click.prevent="infoTab = 'evaluation'">
								<inline-svg :src="require('../assets/evaluation.svg')" width="26"></inline-svg>
								<span>Evaluation</span>
							</a>
						</div>
						<div class="live-track__info__content is-mobile">
							<EventInfo v-if="infoTab === 'info' && currentSession" :session="currentSession" />
							<div v-show="infoTab === 'chat' && currentSessionId && sessionHasChat" ref="chatWrapper" class="live-track__chat">
								<PFChat
									v-show="currentSessionId"
									:p-parent-guid="currentSessionId"
									:p-channel-name="cyimChannelName"
									:p-person="currentUser"
									:p-moderation-on="sessionModerated"
									:p-display-avatar="true"
									:p-avatar-url="currentUserImage"
									@hover:author="hoverAuthor"
									@leave:author="leaveAuthor"
								/>
								<div v-if="userInfo && userInfo.firstName" class="live-track__user-box" :style="userInfoStyle" @mouseenter="userBoxEnter" @mouseleave="userBoxLeave">
									<div class="live-track__user-box-main">
										<router-link class="image" :to="{ name: 'AttendeeDetail', params: { id: userInfo.id } }">
											<img v-if="hasFrame(userInfo)" :src="platformSettings.profileFrameImage | imageUrl" class="frame" />
											<inline-svg v-if="!userInfo.image && userInfo.gender === 'Male'" :src="require('../assets/user_male.svg')" width="25" />
											<inline-svg v-else-if="!userInfo.image && userInfo.gender === 'Female'" :src="require('../assets/user_female.svg')" width="25" />
											<inline-svg v-else-if="!userInfo.image" :src="require('../assets/user_generic.svg')" width="25" />
											<img v-else :src="userInfo.image | imageUrl('thumbnail')" />
										</router-link>
										<router-link class="user-box-name" :to="{ name: 'AttendeeDetail', params: { id: userInfo.id } }">
											<strong>{{ userInfo.firstName }} {{ userInfo.lastName }}</strong>
											<span v-if="userInfo.job_title || userInfo.company"
												>{{ userInfo.job_title }} <span v-if="userInfo.job_title && userInfo.company">at</span> {{ userInfo.company }}</span
											>
										</router-link>
									</div>
									<div v-if="!isCurrentUser(userInfo)" class="live-track__user-box-actions">
										<a v-if="!isFriend(userInfo) && !userInfo.incoming_req && !userInfo.outgoing_req" class="button is-primary" @click="sendFriendRequest(userInfo)">
											<inline-svg :src="require('../assets/user-add.svg')" width="25"></inline-svg>
											Connect
										</a>
										<span v-if="isFriend(userInfo)" class="button is-primary">
											<inline-svg :src="require('../assets/user-check.svg')" width="25"></inline-svg>
											Connected
										</span>
										<span v-if="userInfo.incoming_req" class="button is-primary">
											<span>Sent</span>
										</span>
										<router-link :to="{ name: 'Chat', query: { user: userInfo.id }, params: { user: userInfo.id } }" class="button is-primary" target="_blank">
											<inline-svg :src="require('../assets/topic.svg')" width="20"></inline-svg>
											Chat
										</router-link>
									</div>
								</div>
							</div>
							<div v-show="infoTab === 'vote' && currentSessionId && sessionHasVote" :class="{ 'live-track__vote': voteActive, 'live-track__none': !voteActive }">
								<PFPollRemote :p-user="currentUser" :p-channel-name="cyimChannelName" :p-session-guid="currentSessionId" @show="showPoll" @close="closePoll"></PFPollRemote>
								<span v-if="voteActive === false">No voting is currently available</span>
							</div>
							<div v-if="infoTab === 'evaluation' && currentSessionId && sessionHasEvaluation">
								<iframe v-if="evaluationUrl" :src="evaluationUrl"></iframe>
							</div>
							<div v-if="infoTab === 'info' && !currentSession" class="live-track__none">
								<span>No sessions are currently available</span>
							</div>
							<div v-if="infoTab === 'chat' && !currentSessionId" class="live-track__none">
								<span>No sessions are currently available</span>
							</div>
							<div v-if="infoTab === 'chat' && currentSessionId && !sessionHasChat" class="live-track__none">
								<span>The chat feature is at-present inactive for this session</span>
							</div>
							<div v-if="infoTab === 'vote' && !currentSessionId" class="live-track__none">
								<span>No sessions are currently available</span>
							</div>
							<div v-if="infoTab === 'evaluation' && !currentSessionId" class="live-track__none">
								<span>No sessions are currently available</span>
							</div>
							<div v-if="infoTab === 'vote' && currentSessionId && !sessionHasVote" class="live-track__none">
								<span>The voting feature is at-present inactive for this session</span>
							</div>
							<div v-if="infoTab === 'evaluation' && currentSessionId && !sessionHasEvaluation" class="live-track__none">
								<span>The evaluation feature is at-present inactive for this session</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import session from '../utils/session';
	import axios from 'axios';
	import Vue from 'vue';
	import LoginModal from '../components/LoginModal';
	import { PFChat, PFPollRemote } from '@congress/platform-components-eaaci';

	import EventInfo from '../components/EventInfo';
	import { CYIM_BEARER, ANALYTICS_URL } from '../consts';
	import meta from '../utils/meta';
	import platformSettings from '../utils/platformSettings';
	import moment from 'moment-timezone';

	const MODERATED = [
		'b15d6dd6-41f7-4f40-8727-6c2ec184dd67',
		'af7e1164-3f80-412f-a343-77254b8ce086',
		'ccbe654d-1c38-4382-9713-4785997e127b',
		'cc5d9057-6097-457a-888c-5b26b703e2b1',
		'614e75ec-33e2-4645-970e-b72eff4c61d2',
		'c4d7edc4-ae2a-4958-93de-cd9c0db7f6b7',
	];

	const CHANNEL_NAME = '5b0bda00-82e8-4340-a0a2-929ecce0933f';

	const debounce = (fn, delay) => {
		var timeoutID = null;
		return function() {
			clearTimeout(timeoutID);
			var args = arguments;
			var that = this;
			timeoutID = setTimeout(function() {
				fn.apply(that, args);
			}, delay);
		};
	};

	var throttle = (func, ms = 50, context = window) => {
		let to;
		let wait = false;
		return (...args) => {
			let later = () => {
				func.apply(context, args);
			};
			if (!wait) {
				later();
				wait = true;
				to = setTimeout(() => {
					wait = false;
				}, ms);
			}
		};
	};

	const throttled = throttle(func => {
		func();
	}, 30000);

	const axiosWithoutAuth = axios.create({
		headers: {
			'Content-Type': 'application/json',
			// Authorization: `Bearer ${CYIM_BEARER}`,
		},
	});

	const axiosAnalytics = axios.create({
		baseURL: ANALYTICS_URL,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${session.state.key}`,
		},
	});

	export default {
		name: 'LiveTrack',
		components: { EventInfo, PFChat, PFPollRemote },
		data() {
			return {
				track: {},
				liveNow: undefined,
				logInit: false,
				interval: null,
				intervalTimeout: 60000,
				refreshInterval: null,
				refresh: 0,
				currentSession: false,
				infoTab: 'info',
				session: { user: {} },
				player: null,
				loaded: false,
				voteActive: false,
				showVote: true,
				translationOpen: false,
				wordlyDisclaimer: true,
				playBlock: false,
				platformSettings: platformSettings.state,
				userInfo: {},
				userInfoStyle: {},
				userInfoShowTimeout: null,
				userInfoHideTimeout: null,
				userInfoCurrentId: null,
				fakeTempBannerVisible: false,
				cyimChannelName: CHANNEL_NAME,
				token: false,
			};
		},
		beforeRouteEnter(to, from, next) {
			axiosWithoutAuth
				.get(`/live-tracks/${to.query.live_track_url}`, {
					headers: {
						Authorization: `Bearer ${to.query.token}`,
					},
				})
				.then(
					data => {
						next(vm => {
							vm.setToken(to.query.token);
							vm.setData(data);
						});
					},
					err => {
						if (err.response.status === 403) {
							window.sessionStorage.setItem('afterLogin', JSON.stringify({ name: to.name, params: to.params }));
							Vue.prototype.$modal.show(
								LoginModal,
								{},
								{
									classes: 'login-modal',
									width: '90%',
									maxWidth: 550,
									height: 'auto',
									adaptive: true,
								},
								{
									'before-close': () => {
										next(false);
										window.sessionStorage.removeItem('afterLogin');
										this.$Progress.finish();
									},
								}
							);
						}
					}
				);
		},
		beforeRouteUpdate(to, from, next) {
			axiosWithoutAuth
				.get(`/live-tracks/${to.query.live_track_url}`, {
					headers: {
						Authorization: `Bearer ${to.query.token}`,
					},
				})
				.then(data => {
					this.setData(data);
					this.setToken(to.query.token);
					next();
				});
		},
		beforeRouteLeave(to, from, next) {
			if (window.wowza) {
				window.sessionStorage.setItem('forceReload', JSON.stringify({ name: to.name, params: to.params }));
				location.reload();
			} else {
				next();
			}
		},
		computed: {
			currentSessionId() {
				if (!this.liveNow) return false;
				if (this.liveNow.length > 0) {
					if (this.liveNow[0].session && this.liveNow[0].session.id) {
						return this.liveNow[0].session.id;
					}
				}
				return false;
			},
			currentUser() {
				if (!this.session.user.id || !this.session.user.firstName || !this.session.user.lastName) return false;
				return { id: `${this.session.user.id}`, name: `${this.session.user.firstName} ${this.session.user.lastName}` };
			},
			currentUserImage() {
				if (!this.session.user.id) return '';
				if (!this.session.user.image) return '';
				return this.$options.filters.imageUrl(this.session.user.image);
			},
			sessionHasChat() {
				if (!this.currentSession) return false;
				return this.currentSession.hasQna === true;
			},
			sessionHasVote() {
				if (!this.currentSession) return false;
				return this.currentSession.hasVoting === true;
			},
			sessionHasEvaluation() {
				if (!this.currentSession) return false;
				return !!this.currentSession.evaluationUrl;
			},
			evaluationUrl() {
				if (!this.currentSession) return false;
				return this.currentSession.evaluationUrl;
			},
			sessionModerated() {
				if (!this.currentSession) return false;
				if (!this.currentSessionId) return false;
				if (this.currentSessionId) {
					if (MODERATED.indexOf(this.currentSessionId.toLowerCase()) !== -1) return true;
				}
				return false;
			},
		},
		mounted() {
			this.interval = setInterval(() => {
				this.getCurrentSession();
			}, this.intervalTimeout);

			this.refreshInterval = window.setInterval(() => {
				this.isTempFakeBannerVisible();
			}, 30 * 1000);
			this.isTempFakeBannerVisible();

			let disclaimer = window.localStorage.getItem('wordlyDisclaimer');
			if (disclaimer && disclaimer === 'true') {
				this.wordlyDisclaimer = false;
			}

			axiosWithoutAuth
				.get(`/user-info`, {
					headers: {
						Authorization: `Bearer ${this.token}`,
					},
				})
				.then(data => {
					this.$set(this.session, 'user', data.data);
				});
		},
		beforeDestroy() {
			clearInterval(this.interval);
			if (this.refreshInterval) {
				window.clearInterval(this.refreshInterval);
			}
		},
		methods: {
			setData(data) {
				this.track = data.data;
				meta.setTitle(this.track.title);

				this.getCurrentSession();
			},
			setToken(token) {
				this.token = token;
			},
			getCurrentSession() {
				if (this.track && this.track.live_now_guid) {
					axiosWithoutAuth
						.post('https://gql.cyim.com/graphql', {
							query:
								'query currentSessionByTrack($locationId: ID!) { location(id: $locationId) { id name broadcasts(filter: { onAir: true}) { items { session: source { ... on Event { id title } } } } }}',
							variables: { locationId: this.track.live_now_guid },
						})
						.then(({ data }) => {
							if (data && data.data && data.data.location && data.data.location.broadcasts && data.data.location.broadcasts.items) {
								this.liveNow = data.data.location.broadcasts.items;
								if (this.currentSessionId) {
									axiosWithoutAuth
										.post('https://gql.cyim.com/graphql', {
											query:
												'query sessionInformation ($sessionId : ID!){ session: event(id: $sessionId) { _id: id color title duration location { id } onAir: ongoing typeCommunication: tag(category: "/session/type") start end participants(role:"chair") { items { _id: id firstName lastName photo { uri: url _id: id } } } childEvents: schedule { items { _id: id title start duration participants(role: "speaker") { items { _id: id firstName lastName photo { uri: url _id: id } } } } } hasVoting: tag(category: "/interactivity/voting") hasQna: tag(category: "/interactivity/qna") evaluationUrl: tag(category: "/session/evaluation") }}',
											variables: { sessionId: this.currentSessionId },
										})
										.then(sessionData => {
											if (sessionData.data && sessionData.data.data && sessionData.data.data.session) {
												this.currentSession = sessionData.data.data.session;
											}
										});
								} else {
									this.currentSession = false;
								}
							}
						});
				}
			},
			logPlay() {
				if (this.logInit === false) {
					this.logInit = true;
				} else {
					axiosAnalytics.post('/analytics', {
						trackId: this.track.id,
						trackTitle: this.track.title,
						actionId: 'watched_30s',
					});
				}
			},
			hoverAuthor(e) {
				if (e && e.event && e.userId) {
					if (e.userId !== this.userInfoCurrentId) {
						if (this.userInfoHideTimeout) window.clearTimeout(this.userInfoHideTimeout);
						this.userInfo = {};
						this.userInfoCurrentId = null;

						axiosWithoutAuth.get(`/users/network/${e.userId}`, {
								headers: {
									Authorization: `Bearer ${this.token}`,
								},
							}).then(
							res => {
								this.$nextTick(() => {
									this.userInfoCurrentId = e.userId;
									this.userInfo = res.data;
								});
							},
							() => {
								this.userInfo = {};
							}
						);
					} else if (e.userId === this.userInfoCurrentId) {
						if (this.userInfoHideTimeout) window.clearTimeout(this.userInfoHideTimeout);
					}

					if (e.event.target) {
						let dim = e.event.target.getBoundingClientRect();
						let wr = this.$refs.chatWrapper.getBoundingClientRect();

						this.userInfoStyle = { top: dim.top - wr.top + 30 + 'px', left: dim.left - wr.left + 30 + 'px' };
					}
				}
			},
			leaveAuthor(e) {
				if (this.userInfoHideTimeout) window.clearTimeout(this.userInfoHideTimeout);
				this.userInfoHideTimeout = window.setTimeout(() => {
					this.userInfo = {};
					this.userInfoCurrentId = null;
				}, 800);
			},
			userBoxEnter(e) {
				this.$nextTick(() => {
					if (this.userInfoHideTimeout) window.clearTimeout(this.userInfoHideTimeout);
				});
			},
			userBoxLeave(e) {
				this.userInfoHideTimeout = window.setTimeout(() => {
					this.userInfo = {};
					this.userInfoCurrentId = null;
				}, 200);
			},
			showPoll(data) {
				this.infoTab = 'vote';
				this.voteActive = true;
			},
			closePoll() {
				this.voteActive = false;
			},
			wordlyAgree() {
				window.localStorage.setItem('wordlyDisclaimer', 'true');
				this.wordlyDisclaimer = false;
			},
			onFeeHit(status) {
				this.playBlock = status;
				if (status === true) {
					this.player.stop();
				}
			},
			onEvaluate() {
				if (this.sessionHasEvaluation) {
					this.infoTab = 'evaluation';
				}
			},
			hasFrame(attendee) {
				return (
					attendee.eaaci_membership === true && attendee.profile_frame_enabled === true && this.platformSettings.profileFrameEnabled && this.platformSettings.profileFrameImage
				);
			},
			isCurrentUser(attendee) {
				return attendee.id === this.session.user.id;
			},
			isFriend(attendee) {
				return attendee.email !== null || attendee.friend === true;
			},
			sendFriendRequest(attendee) {
				if (this.session.user.id) {
					axiosWithoutAuth
						.post(
							'/friend-requests',
							{
								author: this.session.user.id,
								recipient: attendee.id,
							},
							{
								headers: {
									Authorization: `Bearer ${this.token}`,
								},
							}
						)
						.then(() => {
							this.$set(attendee, 'incoming_req', true);
						});
				}
			},
			isTempFakeBannerVisible() {
				let target = moment.tz.guess();
				let dStart1 = moment.tz(`2022-07-01 17:15`, 'CET');
				let dEnd1 = moment.tz(`2022-07-01 17:45`, 'CET');
				let dStart2 = moment.tz(`2022-07-02 12:30`, 'CET');
				let dEnd2 = moment.tz(`2022-07-02 13:00`, 'CET');
				let now = moment().tz(target);
				this.fakeTempBannerVisible = this.track.url === 'channel6' && (now.isBetween(dStart1, dEnd1) || now.isBetween(dStart2, dEnd2));
			},
		},
	};
</script>
